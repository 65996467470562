import React, { ReactElement, useEffect, useState } from 'react';
import { Checkbox, Select, Form, Input } from 'antd';
import styles from './DatastoreSettingsDbParameters.module.less';
import './DatastoreSettingsDbParameters.less';
import AppTable from '../../../AppTable';
import AppLoading from '../../../AppLoading';
import { Type } from '../../../../types/DbParameter';
import { EditOutlined } from '@ant-design/icons';
import InfoIcon from '@severalnines/bar-frontend-components/build/lib/General/InfoIcon';
import DbParameters from '../../../../types/DbParameters';

interface Props {
    isEditMode: boolean;
    onChange: (name: any, value: string | boolean) => void;
    dbParameters: DbParameters | undefined;
    loading: boolean;
}

function DatastoreSettingDbParameters({
    isEditMode,
    onChange,
    loading,
    dbParameters,
}: Props): ReactElement {
    const { Option } = Select;
    const [editableFields, setEditableFields] = useState<any[]>([]);

    const getFormattedValues = (value: any, isDefault: boolean) => {
        const formattedValue = value
            .split(',')
            .reduce((result: any, item: any, index: number) => {
                const commaCount = result.split(',').length - 1;
                if (result === '' && isDefault) {
                    return `Default: ${item}`;
                } else if (result === '' && !isDefault) {
                    return `${item}`;
                } else if (commaCount % 2 === 1) {
                    return `${result},\n${item}`;
                } else {
                    return `${result},${item}`;
                }
            }, '');

        return formattedValue;
    };

    useEffect(() => {
        if (!isEditMode) {
            setEditableFields([]);
        }
    }, [isEditMode]);

    const getMinMaxDefaultValues = (record: any) => {
        return (
            <div className={styles.DatastoreSettingDbParametersDefault}>
                {record?.type === Type.NUMBER && (
                    <>
                        <span>{`Max: ${record?.max} | `}</span>
                        <span>{`Min: ${record?.min} | `}</span>
                    </>
                )}
                {record?.defaultValue && (
                    <span>
                        {getFormattedValues(record?.defaultValue, true)}
                    </span>
                )}
            </div>
        );
    };

    function renderInputField(record: any) {
        switch (record?.type) {
            case Type.BOOLEAN:
                return (
                    <Checkbox
                        defaultChecked={
                            record?.value
                                ? JSON.parse(record?.value)
                                : JSON.parse(record?.defaultValue)
                        }
                        onChange={(e) =>
                            onChange(record?.name, e.target.checked)
                        }
                    />
                );
            case Type.SELECT:
                return (
                    <Select
                        defaultValue={
                            record?.value ? record?.value : record?.defaultValue
                        }
                        className={styles.DatastoreSettingDbParametersSelect}
                        onChange={(value) => onChange(record?.name, value)}
                    >
                        {record?.options?.map((item: any, index: number) => {
                            return (
                                <Option value={item} key={`${index}`}>
                                    {item}
                                </Option>
                            );
                        })}
                    </Select>
                );
            case Type.NUMBER:
                return (
                    <Form.Item
                        name={record?.name}
                        className={styles.DatastoreSettingDbParametersField}
                    >
                        <Input
                            type="number"
                            autoComplete="off"
                            min={record?.min}
                            max={record?.max}
                            defaultValue={
                                record?.value
                                    ? record?.value
                                    : record?.defaultValue
                            }
                            onChange={(e) =>
                                onChange(record?.name, e.target.value)
                            }
                        />
                    </Form.Item>
                );
            case Type.TEXT:
                return (
                    <Form.Item
                        name={record?.name}
                        className={styles.DatastoreSettingDbParametersField}
                    >
                        <Input.TextArea
                            autoComplete="off"
                            defaultValue={
                                record?.value
                                    ? record?.value
                                    : record?.defaultValue
                            }
                            onChange={(e) =>
                                onChange(record?.name, e.target.value)
                            }
                        />
                    </Form.Item>
                );

            default:
                return null;
        }
    }

    const toggleEditableField = (fieldName: any) => {
        if (editableFields.includes(fieldName)) {
            setEditableFields(
                editableFields.filter((field) => field !== fieldName)
            );
        } else {
            setEditableFields([...editableFields, fieldName]);
        }
    };

    const databasesColumns = [
        {
            title: 'Parameter Name',
            key: 'name',
            width: 450,
            render: (text: string, record: any) => {
                return (
                    record && (
                        <div>
                            <span>
                                <span
                                    className={
                                        styles.DatastoreSettingDbParametersName
                                    }
                                >
                                    {record?.name}
                                </span>
                                <InfoIcon
                                    info={<span>{record.description}</span>}
                                />
                            </span>
                            <br />
                            {getMinMaxDefaultValues(record)}
                        </div>
                    )
                );
            },
        },
        {
            title: 'Current Value',
            width: 650,
            key: 'value',
            render: (text: string, record: any) => {
                if (editableFields.includes(record?.name) && isEditMode) {
                    return renderInputField(record);
                } else {
                    return (
                        <div
                            className={styles.DatastoreSettingDbParametersEdit}
                        >
                            <span>
                                {getFormattedValues(record?.value, false)}
                            </span>
                            {!editableFields.includes(record?.name) &&
                                isEditMode && (
                                    <span
                                        className={
                                            styles.DatastoreSettingDbParametersEditIcon
                                        }
                                    >
                                        <EditOutlined
                                            onClick={() =>
                                                toggleEditableField(record.name)
                                            }
                                        />
                                    </span>
                                )}
                        </div>
                    );
                }
            },
        },
    ];

    return loading ? (
        <AppLoading />
    ) : (
        <AppTable
            columns={databasesColumns}
            data={dbParameters?.parameters}
            rowKey="uniqueKey"
            pagination={{
                hideOnSinglePage: true,
            }}
            expandable={false}
        />
    );
}

export default DatastoreSettingDbParameters;
